<template>
  <Layout>
    <SchedulerPrint />
  </Layout>
</template>

<script>
import Layout from '@layouts/printScheduleLayout'
import SchedulerPrint from '@components/SchedulerView/SchedulerPrint'
import { mapActions } from 'vuex'

export default {
  name: 'SchedulerPrintView',
  metaInfo: {
    title: 'Scheduler Two Week Print',
  },
  components: { Layout, SchedulerPrint },
  beforeMount() {
    this.setIsPrintMode(true)
  },
  methods: mapActions(['setIsPrintMode']),
}
</script>
