<template>
  <VFlex grow :class="['crew-calendar', { dragging: isDragging }]" px-2>
    <CalendarHeader />
    <WeekHeader
      :start-date="startDate"
      :current-date-range="currentDateRange"
    />
    <VLayout fill-height>
      <VFlex grow>
        <VLayout align-space-around column fill-height class="pb-3">
          <transition name="fade-fast" mode="out-in">
            <div v-if="didTimeout" class="cal-timeout-error white--text"
              ><h3
                >There was a problem loading the calendar.<br />Please reload
                the page.</h3
              >
              <BaseButton
                depressed
                small
                color="white"
                outline
                class="reload-button"
                @click="reloadPage"
                >Reload</BaseButton
              >
              <p>If this problem continues, contact tech support.</p>
            </div>
            <BaseSpinner
              v-else-if="areReportsLoading"
              :message="`Loading ${currentDateRange}`"
            />
            <div v-else class="cal-body-inner flex">
              <WeekBody
                v-for="(foreman, i) in foremen"
                :key="JSON.stringify(foreman)"
                :class="`cal-row ${i + 1 === foremen.length ? 'last' : ''}`"
                :start-date="startDate"
                :foreman="foreman"
                :week="reportsByForemanId[foreman.ID] || []"
                @createGhost="createGhost(...arguments)"
                @reportAdded="addSchedReport"
              />
            </div>
          </transition>
        </VLayout>
      </VFlex>
    </VLayout>
  </VFlex>
</template>

<script>
import dayjs from '@utils/dayjsCustomParse.js'

import { mapGetters, mapActions } from 'vuex'

const WeekHeader = () =>
  import(/* webpackChunkName: 'WeekHeader' */ '@scheduler/WeekHeader')
const WeekBody = () =>
  import(/* webpackChunkName: 'WeekBody' */ '@scheduler/WeekBody')
const CalendarHeader = () =>
  import(/* webpackChunkName: 'CalendarHeader' */ '@scheduler/CalendarHeader')

export default {
  name: 'CrewCalendar',

  components: {
    WeekHeader,
    WeekBody,
    CalendarHeader,
  },
  props: {
    foremen: {
      type: Array,
      default: () => [],
    },
    didTimeout: {
      type: Boolean,
      default: false,
    },
    areReportsLoading: {
      type: Boolean,
      default: false,
    },
    reportsByForemanId: {
      type: Object,
      default: () => {},
    },
    reports: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isDragging', 'getIsPrintMode']),
    currentDateRange() {
      let numDaysToShow = this.getIsPrintMode ? 14 : 7
      return `${dayjs(this.startDate, 'MM-DD-YYYY').format('M/D')} – ${dayjs(
        this.startDate,
        'MM-DD-YYYY'
      )
        .add(numDaysToShow, 'days')
        .format('M/D')}`
    },
    startDate() {
      return this.$route.query.startDate || dayjs().format('MM-DD-YYYY')
    }, // startDate
  },
  methods: {
    ...mapActions(['addSchedReport']),
    reloadPage() {
      window.location.reload()
    }, // reloadPage
  }, // methods
}
</script>

<style lang="scss">
.crew-calendar {
  display: flex;
  flex: 0 0 70%;
  flex-direction: column;
  max-height: 100vh;
  margin: 0 auto;
  overflow: auto;

  @media screen {
    background-color: #9e9e9e;
  }

  @media print {
    .cal-cell,
    .cal-cell.weekend-header {
      border-color: black;
    }
  }
}

.header--wrap {
  position: sticky;
  top: 0;
  z-index: 5;
  @media print {
    position: relative;
  }
}

.calendar-inner {
  flex: 1 0 100%;
  // min-height: calc(100% - 120px);
  min-height: calc(100% - 55px);
}

.cal-body-inner {
  display: flex;
  flex-direction: column;
}

.cal-timeout-error {
  margin-top: 20px;
}

.reload-button {
  cursor: pointer;
}
</style>
