<script>
import { mapActions, mapGetters } from 'vuex'
import { HumanFields, LocationStatuses } from '@constants/knack'
import dayjs from '@utils/dayjsCustomParse'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  data() {
    return {
      isCalendarLoading: false,
      areLocationsLoading: false,
      didTimeout: false,
      timeoutLengthInSeconds: 15,
    }
  },
  computed: {
    ...mapGetters([
      'getReportsResponse',
      'getReports',
      'getProgressTotal',
      'getProgressCounter',
      'getLocationFromId',
      'getAllLocations',
      'getForemen',
      'getIsPrintMode',
    ]),
    ...mapFields([
      'selectedClientNames',
      'selectedJobNumber',
      'isShowingCivilJobsOnly',
      'isShowingFiberJobsOnly',
    ]),
    isBatchUpdating() {
      return this.getProgressCounter < this.getProgressTotal
    },
    // full repsonse object including records and metadata too
    response() {
      return this.getReportsResponse
    },
    fetchedReports() {
      return this.getReports
    },
    fetchQuery() {
      let fetchQuery = {
        rows_per_page: 1000,
        view_mode: true,
      }
      /**
       * Process Date Range Filters
       */
      let startDate = this.$route.query.startDate
        ? dayjs(this.$route.query.startDate, 'MM-DD-YYYY')
        : dayjs(Date.now())

      let numDaysToShow = this.getIsPrintMode ? 14 : 7
      let endDate = startDate.add(numDaysToShow, 'day')
      let filters = {
        match: 'and',
        rules: [],
      }

      filters.rules.push({
        field: HumanFields.DAILY_REPORT.REPORT_DATE,
        type: 'date',
        operator: 'is after',
        value: {
          all_day: false,
          date: startDate.subtract(1, 'day').format('MM/DD/YYYY'),
        },
      })
      filters.rules.push({
        field: HumanFields.DAILY_REPORT.REPORT_DATE,
        type: 'date',
        operator: 'is before',
        value: {
          all_day: false,
          date: dayjs(endDate)
            .add(1, 'day')
            .format('MM/DD/YYYY'),
        },
      })

      // filters.rules.push({
      //   field: HumanFields.DAILY_REPORT.REPORT_STATUS,
      //   operator: 'is not',
      //   value: ReportStatuses.LOCKED,
      // })

      fetchQuery.filters = filters

      return fetchQuery
    }, // fetchQuery

    filtersByLocStatusAndPermittedEndDate() {
      let statusRules = Object.values(LocationStatuses)
        .filter(
          (status) =>
            ![
              LocationStatuses.READY_TO_WORK,
              LocationStatuses.WORK_SCHEDULED,
            ].includes(status)
        )
        .map((status) => {
          return {
            field: HumanFields.LOCATIONS.STATUS,
            operator: 'is not',
            value: status,
          }
        })
      let rules = [
        ...statusRules,
        {
          field: HumanFields.LOCATIONS.PERMITTED_END_DATE,
          operator: 'is today or after',
        },
      ]
      return {
        view_mode: true,
        rows_per_page: 1000,
        filters: {
          match: 'and',
          rules: rules,
        },
      }
    }, // filtersByLocStatusAndPermittedEndDate

    reports() {
      let reports = this.fetchedReports || []
      // Add location data when it becomes available
      let withLocationData = reports.map((r) => {
        let locId = _get(r, 'LOCATION[0].id', 0)
        // let loc = locations.find((l) => l.ID === locId) || {}
        let loc = this.getLocationFromId(locId) || {}
        return { ...r, ...{ locationData: loc } }
      })
      return withLocationData
    }, // reports

    filteredReports() {
      let reports = this.reports
      let filteredReports = reports.filter((r) => {
        let isMatch = true
        if (this.selectedClientNames.length) {
          isMatch = this.selectedClientNames.includes(
            _get(r, 'CLIENT_NAME', '')
          )
        }
        if (this.selectedJobNumber) {
          isMatch = isMatch && this.selectedJobNumber === r.JOB_NUMBER
        }
        if (this.isShowingCivilJobsOnly) {
          isMatch = isMatch && _get(r, 'JOB_CONTRACT_NUMBER', '') !== ''
        }
        if (this.isShowingFiberJobsOnly) {
          isMatch =
            isMatch &&
            ['Fiber', 'Micro', 'Micro Repair'].includes(_get(r, 'JOB_TYPE', ''))
        }
        return isMatch
      })
      return filteredReports
    }, // filteredReports

    reportsWithoutLocations() {
      let withoutLocations = this.reports.filter(
        (r) => !_get(r, 'locationData.ID', false)
      )
      return withoutLocations
    }, // reportsWithoutLocations

    reportsByForemanId() {
      let foremen = _groupBy(this.filteredReports, 'FOREMAN[0].id')
      const ordered = {}
      Object.keys(foremen)
        .sort()
        .forEach(function(key) {
          ordered[key] = foremen[key]
        })
      return ordered
    }, // reportsByForemanId

    foremenWithReports() {
      let foremenIdsFromReports = Object.keys(this.reportsByForemanId) || []
      if (!this.getForemen) {
        return []
      }
      let activeForemenWithReports = this.getForemen.filter(
        (f) => foremenIdsFromReports.includes(f.ID) || f.STATUS === 'Active'
      )
      return _orderBy(activeForemenWithReports, 'SCHEDULER_ORDER')
    }, // foremenWithReports

    supplementalLocationsQuery() {
      let locationIdentifiers = this.reportsWithoutLocations.map((r) => {
        let identifier = _get(r, 'LOCATION[0].identifier', 0)
        if (!identifier) {
          // eslist-disable-next-line
          console.warn('Malformed Report : No Location found', r)
        }
        return identifier
      })
      let filters = {
        match: 'or',
        rules: _uniq(locationIdentifiers).map((identifier) => {
          return {
            field: HumanFields.LOCATIONS.IDENTIFIER,
            operator: 'is',
            value: identifier,
          }
        }),
      }
      return { view_mode: true, filters: filters }
    }, // supplementalLocationsQuery

    locations() {
      return this.getAllLocations
    }, // locations
  }, // computed
  watch: {
    $route: {
      async handler(val) {
        this.isCalendarLoading = true
        this.areLocationsLoading = true
        this.didTimeout = false
        let timeout = setTimeout(() => {
          this.didTimeout = true
        }, this.timeoutLengthInSeconds * 1000)

        await this.fetchLocations(this.filtersByLocStatusAndPermittedEndDate)
        this.areLocationsLoading = false
        await this.fetchForemen()
        await this.fetchReports(this.fetchQuery)
        this.isCalendarLoading = false

        if (this.reportsWithoutLocations.length) {
          // console.log(
          //   `reportsWithoutLocations : ${this.reportsWithoutLocations.length}`,
          //   { locationsQuery: this.supplementalLocationsQuery }
          // )
          await this.fetchCalendarLocations(this.supplementalLocationsQuery)
        }
        this.setScheduleAsNotLoading()
        clearTimeout(timeout)
        this.didTimeout = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'fetchLocations',
      'fetchReports',
      'fetchCalendarLocations',
      'fetchForemen',
      'setScheduleAsNotLoading',
    ]),
  },
  render() {
    return this.$scopedSlots.default({
      isCalendarLoading: this.isCalendarLoading,
      areLocationsLoading: this.areLocationsLoading,
      didTimeout: this.didTimeout,
      reports: this.filteredReports,
      reportsByForemanId: this.reportsByForemanId,
      foremen: this.foremenWithReports || [],
      locations: this.locations || [],
    })
  },
}
</script>
